import { SearchIcon, AdjustmentsIcon, ShareIcon } from '@heroicons/react/outline'

const transferFeatures = [
    {
        id: 1,
        name: 'Focused notifications',
        description:
            'Choose only the metrics and signals your most interested in. Setup up rules to automatically mute and unmute them bases on your preference or on-chain data',
        icon: SearchIcon,
    },
    {
        id: 2,
        name: 'Multiple time horizons',
        description:
            'Whether your interest is in the next 5 minutes, or the next 5 days, or both, you can pick the time horizons that matter most to you',
        icon: AdjustmentsIcon,
    },
    {
        id: 3,
        name: 'Contact method of choice',
        description:
            'Email, Slack, Telegram, and RSS can be used to receive updates as they happen.',
        icon: ShareIcon,
    },
]

export default function FeaturesC() {
    return (
        <div id = "act" className="py-16 bg-gray-50 overflow-hidden lg:py-24">
            <div className="relative max-w-xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl">
                <svg
                    className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
                    width={404}
                    height={784}
                    fill="none"
                    viewBox="0 0 404 784"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="b1e6e422-73f8-40a6-b5d9-c8586e37e0e7"
                            x={0}
                            y={0}
                            width={20}
                            height={20}
                            patternUnits="userSpaceOnUse"
                        >
                            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                        </pattern>
                    </defs>
                    <rect width={404} height={784} fill="url(#b1e6e422-73f8-40a6-b5d9-c8586e37e0e7)" />
                </svg>

                {/*<div className="relative">*/}
                {/*    <h2 className="text-center text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">*/}
                {/*        A better way to send money*/}
                {/*    </h2>*/}
                {/*    <p className="mt-4 max-w-3xl mx-auto text-center text-xl text-gray-500">*/}
                {/*        Lorem ipsum dolor sit amet consectetur adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in,*/}
                {/*        accusamus quisquam.*/}
                {/*    </p>*/}
                {/*</div>*/}

                <div className="relative mt-12 lg:mt-16 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
                    <div className="relative">
                        <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">
                            <span className="line-through">Reactive?</span> Proactive.
                        </h3>
                        <p className="mt-3 text-lg text-gray-500">
                            Metrics and signals can be automatically pushed to you across multiple channels so you can take action on your own terms.
                        </p>

                        <dl className="mt-10 space-y-10">
                            {transferFeatures.map((item) => (
                                <div key={item.id} className="relative">
                                    <dt>
                                        <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-cyan-500 text-white">
                                            <item.icon className="h-6 w-6" aria-hidden="true" />
                                        </div>
                                        <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>
                                    </dt>
                                    <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>

                    <div className="mt-10 -mx-4 relative lg:mt-0 p-4" aria-hidden="true">
                        <svg
                            className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                            width={784}
                            height={404}
                            fill="none"
                            viewBox="0 0 784 404"
                        >
                            <defs>
                                <pattern
                                    id="ca9667ae-9f92-4be7-abcb-9e3d727f2941"
                                    x={0}
                                    y={0}
                                    width={20}
                                    height={20}
                                    patternUnits="userSpaceOnUse"
                                >
                                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                                </pattern>
                            </defs>
                            <rect width={784} height={404} fill="url(#ca9667ae-9f92-4be7-abcb-9e3d727f2941)" />
                        </svg>
                        <img
                            className="relative mx-auto bg-cyan-800 p-6 rounded-2xl shadow-lg"
                            width={400}
                            src="/fc.svg"
                            alt=""
                        />
                    </div>
                </div>

                {/*<svg*/}
                {/*    className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"*/}
                {/*    width={404}*/}
                {/*    height={784}*/}
                {/*    fill="none"*/}
                {/*    viewBox="0 0 404 784"*/}
                {/*    aria-hidden="true"*/}
                {/*>*/}
                {/*    <defs>*/}
                {/*        <pattern*/}
                {/*            id="64e643ad-2176-4f86-b3d7-f2c5da3b6a6d"*/}
                {/*            x={0}*/}
                {/*            y={0}*/}
                {/*            width={20}*/}
                {/*            height={20}*/}
                {/*            patternUnits="userSpaceOnUse"*/}
                {/*        >*/}
                {/*            <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />*/}
                {/*        </pattern>*/}
                {/*    </defs>*/}
                {/*    <rect width={404} height={784} fill="url(#64e643ad-2176-4f86-b3d7-f2c5da3b6a6d)" />*/}
                {/*</svg>*/}

                {/*<div className="relative mt-12 sm:mt-16 lg:mt-24">*/}
                {/*    <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">*/}
                {/*        <div className="lg:col-start-2">*/}
                {/*            <h3 className="text-2xl font-extrabold text-gray-900 tracking-tight sm:text-3xl">Always in the loop</h3>*/}
                {/*            <p className="mt-3 text-lg text-gray-500">*/}
                {/*                Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit ex obcaecati natus eligendi delectus,*/}
                {/*                cum deleniti sunt in labore nihil quod quibusdam expedita nemo.*/}
                {/*            </p>*/}

                {/*            <dl className="mt-10 space-y-10">*/}
                {/*                {communicationFeatures.map((item) => (*/}
                {/*                    <div key={item.id} className="relative">*/}
                {/*                        <dt>*/}
                {/*                            <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-indigo-500 text-white">*/}
                {/*                                <item.icon className="h-6 w-6" aria-hidden="true" />*/}
                {/*                            </div>*/}
                {/*                            <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{item.name}</p>*/}
                {/*                        </dt>*/}
                {/*                        <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>*/}
                {/*                    </div>*/}
                {/*                ))}*/}
                {/*            </dl>*/}
                {/*        </div>*/}

                {/*        <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">*/}
                {/*            <svg*/}
                {/*                className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"*/}
                {/*                width={784}*/}
                {/*                height={404}*/}
                {/*                fill="none"*/}
                {/*                viewBox="0 0 784 404"*/}
                {/*                aria-hidden="true"*/}
                {/*            >*/}
                {/*                <defs>*/}
                {/*                    <pattern*/}
                {/*                        id="e80155a9-dfde-425a-b5ea-1f6fadd20131"*/}
                {/*                        x={0}*/}
                {/*                        y={0}*/}
                {/*                        width={20}*/}
                {/*                        height={20}*/}
                {/*                        patternUnits="userSpaceOnUse"*/}
                {/*                    >*/}
                {/*                        <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />*/}
                {/*                    </pattern>*/}
                {/*                </defs>*/}
                {/*                <rect width={784} height={404} fill="url(#e80155a9-dfde-425a-b5ea-1f6fadd20131)" />*/}
                {/*            </svg>*/}
                {/*            <img*/}
                {/*                className="relative mx-auto"*/}
                {/*                width={490}*/}
                {/*                src="https://tailwindui.com/img/features/feature-example-2.png"*/}
                {/*                alt=""*/}
                {/*            />*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </div>
    )
}
