// import Head from 'next/head'
import Hero from '../components/Hero'
import FeaturesA from '../components/FeaturesA'
// import Footer from '../components/Footer'
// import NavBar from '../components/NavBar'
import Layout from '../components/Layout'
import FeaturesB from "../components/FeaturesB";
import FeaturesC from "../components/FeaturesC";
import FeaturesD from "../components/FeaturesD";


export default function Home() {
    return (
        <Layout title={'358 EE OÜ - Blockchain Infomatics'}>
            <Hero />
            <FeaturesA />
            <FeaturesB />
            <FeaturesC />
            <FeaturesD />
        </Layout>
    )

}
