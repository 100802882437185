/* This example requires Tailwind CSS v2.0+ */
import {
    CogIcon,
    FastForwardIcon,
    NewspaperIcon,
    ServerIcon,
    ShieldCheckIcon,
    LightningBoltIcon
} from '@heroicons/react/outline'

const features = [
    { name: 'Resilient', icon: CogIcon, desc: "Cloud-native, redundant architecture for the highest availability" },
    { name: 'Secure', icon: ShieldCheckIcon , desc: "Encryption, access controls, and auditing everywhere to protect your data"},
    { name: 'Fast', icon: LightningBoltIcon , desc: "Extremely performant implementation, suitable for real-time systems"},
    { name: 'Simple', icon: NewspaperIcon , desc: "Expertly crafted API spec and documentation for easy integration"},
    { name: 'REST', icon: ServerIcon , desc: "Industry-standard REST interface for out-of-the-box uses"},
    { name: 'WebSockets', icon: FastForwardIcon , desc: "Advanced, stream-based event delivery for the most demanding applications"},
]

export default function FeaturesD() {
    return (
        <div id = "api" className="relative bg-gray-50 py-16 sm:py-24 lg:py-24">
            <div className="mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
                <h2 className="text-base font-semibold tracking-wider text-cyan-800 uppercase">Move (even) faster</h2>
                <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
                    Direct integration
                </p>
                <p className="mt-5 max-w-prose mx-auto text-xl text-gray-500">
                    Our advanced API exposes all of 358's metrics and signaling in a machine-readable format allowing for simple, real-time assimilation into to whatever you are building.
                </p>
                <div className="mt-12">
                    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
                        {features.map((feature) => (
                            <div key={feature.name} className="pt-6">
                                <div className="flow-root bg-white rounded-lg px-6 pb-8">
                                    <div className="-mt-6">
                                        <div>
                      <span className="inline-flex items-center justify-center p-3 bg-cyan-500 rounded-md shadow-lg">
                        <feature.icon className="h-6 w-6 text-white" aria-hidden="true" />
                      </span>
                                        </div>
                                        <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">{feature.name}</h3>
                                        <p className="mt-5 text-base text-gray-500">
                                            {feature.desc}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}
